*,
html,
body {
  font-family: "Lexend", serif;
  // color: black;
  text-decoration: none;
  // margin: 0;
  // padding: 0;
}

$scheme-color: black;

a {
  text-decoration: none !important;
}

// .home-page {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;;
// }

p {
  font-family: "Lexend", serif;
  color: #000000;
  line-height: initial;
}

.header {
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
}

h1 {
  font-size: 18px !important;
  font-weight: bold;
  margin: 0 !important;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.article-text {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
}

.article-heading {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

h1.go-to-homepage {
  font-size: 15px !important;
}

h1.about-us {
  font-size: 28px !important;
}

p {
  margin: 0 !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-20 {
  font-size: 20px;
}

.fs-15 {
  font-size: 15px;
}

.fs-24 {
  font-size: 24px;
}
.fs-24 {
  font-size: 24px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-35 {
  font-size: 35px;
}

.color-primary {
  color: $scheme-color;
}

.ff-roboto {
  font-family: "Lexend", sans-serif;
  margin-top: 8rem;
}

.ff-playfair {
  font-family: "Playfair Display", serif;
}

.primary-btn-transparent,
.bg-gray-filled-btn,
.bg-primary-filled-btn {
  color: $scheme-color;
  border-radius: 56px;
  border: 1px solid $scheme-color;
  font-size: 12px;
  font-family: "Lexend", sans-serif;
  font-weight: bold;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  padding: 0.4rem 1.5rem;
}

.what-is-new > h1 {
  font-family: "Lexend", sans-serif;
  font-weight: bold;
}

.bg-gray-filled-btn {
  background-color: #f5f5f5;
  // border: none;
  padding: 0.5rem 2rem;
  border: 1px solid $scheme-color;
}

.payment-order-detail-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid #aaa;
  background: #fff;
}

.bg-primary-filled-btn {
  background-color: $scheme-color;
  color: #fff;
  border: 1px solid $scheme-color;
}

.popular-brand-card {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 12px;

  div div {
    background-color: rgba(0, 0, 0, 0.5);

    h1 {
      font-size: 18px;
    }
  }
}

.popular-brand-card-categories {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  width: auto;
  height: 375px;
  div div {
    background-color: rgba(0, 0, 0, 0.25);

    h1 {
      font-size: 18px;
    }
  }
}

.popular-brand-card-categories1 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  // width: auto;
}

.special-offer-section {
  background-color: $scheme-color;
  color: #ffffff;
  h1 {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
    font-family: "Lexend", sans-serif;
  }
}

.new-product-card,
.normal-product-card {
  border-radius: 12px;
  text-align: left;
  border: 0.5px solid #d5d5d5;
  overflow: hidden;
  position: relative;
  .product-title {
    font-size: 14px;
    height: 33px;
    font-family: "Lexend", sans-serif;
  }

  h1 {
    font-family: "Lexend", sans-serif;
  }
}

.new-product-name {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.new-product-category {
  color: #aaa;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;

  span {
    color: #333;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: black !important;
  background-color: black !important;
}

.select-option-button {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
}

.new-product-button {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: #e8e8e8;
  font-size: 14px;
}
.new-product-button-img {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  font-size: 14px;
}
.sortOptions > input[type="radio"] {
  width: 24px;
  height: 24px;
  accent-color: #333;
}
.profile-settings {
}
.new-product-button-auth {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  background: #e8e8e8;
  font-size: 14px;
}

.profile-modal-button {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  background: #e8e8e8;
  font-size: 14px;
}

.new-product-image {
  width: 100%;
  // height: 250px;
  border-radius: 24px;
  object-fit: cover;
}

.new-product-price {
  color: #000;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;

  span {
    color: #aaa;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.new-product-card,
.new-product-tag {
  ::before {
    content: "New";
    position: absolute;
    top: 95%;
    left: 76%;
    width: 36%;
    text-align: center;
    transform: rotate(-45deg);
    display: none;
    background: $scheme-color;
    padding: 6px 0;
    color: white;
    font-size: 10px;
    padding-top: 4px;
    padding-bottom: 6px;
    font-family: "Lexend", sans-serif;
  }
}

.background-primary {
  background: $scheme-color;
  color: white;
  height: 36px;
}

.swal2-container {
  z-index: 2000;
}

.main-heading {
  color: #333;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 95% */
  letter-spacing: -0.4px;
}

.brand-text {
  color: #fff;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 95% */
  letter-spacing: -0.4px;
}

.search-input-border {
  border-style: solid;
  border-width: 1px;
  border-color: #f8e3e8;
  border-radius: 15px;
}

.nav-text {
  color: #aaa;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  margin-left: 5px !important;
}

.profile-modal-text {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.profile-modal-text-red {
  color: red;
}

.category-image-container {
  position: absolute;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 180px;
  width: auto;
}

.category-image {
  width: 304.5px;
  height: 375px;
}

.product-size-labels {
  div {
    // height: 45px;
    // width: 45px;
    border-radius: 12px;
    background-color: #f5f5f5;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    span {
      opacity: 0.5;
      font-size: 13px;
      font-family: "Lexend", sans-serif;
    }
  }
}

.product-size-labels.product-details {
  div {
    padding: 0 4px;
    span {
      font-size: 17px;
    }
  }
}

#product-size-review-section {
  font-size: 14px;
}

.product-colors {
  div {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #d5d5d5;
    cursor: pointer;
  }
}

.category-product-card {
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(255, 255, 255, 0.2),
  //     rgba(0, 0, 0, 0.5)
  //   ),
  //   url('./assets/imgs/category-product-image.png');
  // height: 165px;
  width: auto;
  border-radius: 12px;
  background-position: center;
  background-size: cover;

  div {
    bottom: 10px;
    color: #ffffff;
    left: 0;
    right: 0;
  }
}

.search-input {
  background-color: #f5f5f5;
  border: 0.5px solid #d5d5d5;
  border-radius: 16px;
  padding: 0 1rem;
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  height: 32px;
}

.footer {
  .footer-about-mariza-text {
    // font-size: 12px;
    font-family: "Lexend", sans-serif;
    font-weight: bold;
    text-transform: uppercase !important;
    color: #ffffff;
  }
}

.footer-text {
  color: #aaa;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
  align-self: flex-start;
  white-space: no-wrap;
}

.footer-useful-links {
  color: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.footer-useful-links-option {
  color: #aaa;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
}

.products-list,
.new-products-list,
.brand-products-list,
.brands-list {
  .total-products-label {
    font-family: "Lexend", sans-serif;
    font-weight: bold;
  }

  .filter {
    border-radius: 56px;
    background-color: #f5f5f5;
    span {
      font-size: 12px;
      color: #005288;
      font-family: "Lexend", sans-serif;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.filter-drawer {
  h1 {
    font-size: 24px !important;
    font-weight: bold;
  }
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: $scheme-color;
}

.gradient-horizontal-line,
.gradient-horizontal-line-footer {
  width: 100%;
  background: linear-gradient(to right, white, #000000, white);
  height: 2px;
  border: none;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.gradient-horizontal-line-footer {
  background: linear-gradient(to right, #000000, white, #000000);
  height: 2px;
}

#select-standard-label,
#label {
  font-size: 13px;
  color: $scheme-color;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
}

.terms-and-conditions div,
.refund-and-exchange-policy div,
.privacy-policies div {
  top: 0;
  background-color: #ffffff;
  z-index: 11;
  width: 100%;
  padding: 2rem;
  p {
    font-family: "Lexend", sans-serif;
    font-weight: 16px;
  }
}

.refund-and-exchange-policy div {
  top: -2px;
}

.product-price-details {
  h1.product-price {
    font-size: 32px !important;
    color: $scheme-color;
    font-weight: bold;
    font-family: "Lexend", sans-serif;
  }
}

.cart-header {
  h1 {
    font-family: "Lexend", sans-serif;
    font-weight: bold;
  }
}

.card-product-wrapper:not(:first-child) {
  border: 0.5px solid #d5d5d5;
}

.status-text-details {
  color: #00d03a;
  text-align: right;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.card-product-wrapper2 {
  border: 0.5px solid #d5d5d5;
  padding: 20px;
  // border-radius: 10px;
}

.checkout-amount-container {
  border: 0.5px solid #d5d5d5;
  padding: 24px;
  border-radius: 24px;
  // width: 400px;
  margin-top: 24px;
  // border-radius: 10px;
}

.cart-products {
  border: 0.5px solid #d5d5d5;
  border-radius: 12px;
}

.cart-product {
  img:first-child {
    border-radius: 12px;
  }

  .cart-product-details {
    .product-title,
    .cart-product-quantity,
    .cart-product-size-color,
    .cart-product-price {
      font-size: 14px;
      font-family: "Lexend", sans-serif;
    }
    .cart-product-size-color {
      opacity: 0.5;
    }

    .cart-product-price {
      color: $scheme-color;
    }
  }
}

.cart-items-amount {
  h1 {
    font-size: 20px;
    font-family: "Lexend", sans-serif;
  }
  h1:nth-child(2) {
    color: $scheme-color;
  }
}

.cart-footer,
.checkout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-top: 1px solid #eeeeee;
}

.connect-account-card {
  background-color: rgba(0, 82, 136, 0.1);
  border-radius: 12px;

  p {
    font-size: 12px;
    font-family: "Lexend", sans-serif;
  }

  span {
    font-size: 15px;
    font-weight: bold;
    font-family: "Lexend", sans-serif;
  }
}

.shipping-details {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 20px;
    font-family: "Lexend", sans-serif;
  }
}

.payable-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 24px;
    font-family: "Lexend", sans-serif;
    font-weight: bold;
  }
}

.card-information,
.address-information {
  border: 0.5px solid #d5d5d5;
  border-radius: 12px;

  p:first-child {
    font-size: 14px;
    font-family: "Lexend", sans-serif;
    font-weight: bold;
  }

  .card-number,
  .card-expiry-date,
  .address {
    font-size: 14px;
    opacity: 0.7;
    font-family: "Lexend", sans-serif;
    line-height: initial;
  }
}

.total-amount {
  p {
    font-size: 20px;
    font-weight: bold;
    font-family: "Lexend", sans-serif;
  }
}

.tax-information,
.shipping-information {
  p {
    font-size: 20px;
    font-family: "Lexend", sans-serif;
  }
}

.payable-information {
  p {
    font-size: 24px;
    font-weight: bold;
  }

  p:nth-child(2) {
    color: #005288;
  }
}

.gray-border-12-5px {
  border: 0.5px solid #d5d5d5;
  border-radius: 12px;
}

.br-12 {
  border-radius: 12px;
}

.br-21 {
  border-radius: 21px;
}

.br-50 {
  border-radius: 50%;
}

.bg-gray {
  background-color: #f5f5f5;
}

.add-quantity-icon,
.remove-quantity-icon {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-label-text {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
}

.additional-address-container {
  text-align: start;
}

.shipping-radio:checked {
  background-color: red !important;
  color: red !important;
}

.shopping-type-title {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
  text-align: start;
}

.special-text-delivery {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
  text-align: start;
}

.radio-text {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  text-align: start;
}

.back-button-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #e8e8e8;
  width: 40px;
  height: 40px;
}

.radio-subtext {
  color: #333;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  opacity: 0.5;
  text-align: start;
}

.shipping-type-container {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0.5px solid #aaa;
  border-radius: 15px;
}

.cart-header {
  color: #333;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 95% */
  letter-spacing: -0.4px;
}

.cart-title {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.cart-text {
  color: #333;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
}

.cart-button {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #e8e8e8;
}

.cart-button-pd {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 24px;
  background: #e8e8e8;

  p {
    color: #333;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
}

.cart-button-wl {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 24px;

  p {
    color: #333;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
}

.cart-button-bn {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 24px;
  background: #333;

  p {
    color: #e8e8e8;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-radius: 48px 48px 0px 0px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 24px !important;
}

.dialog-footer-text {
  color: #333;
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.cart-subtotal {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}

.cart-order-btn {
  display: flex;
  width: 398px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: #000;

  p {
    color: #fff;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
}
.checkout-order-btn {
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.cart-span-text {
  color: #aaa;
}

.side-bar-item {
  padding-left: 2rem;
  border-top: 1px solid #eeeeee;
  padding-top: 0.6rem;
  font-weight: 400;
  padding-bottom: 0.6rem;
  p {
    font-size: 20px;
    // font-family: 'Lexend', sans-serif;
    // opacity: 0.7;
  }
}

.side-bar-item.about-us {
  border-bottom: 1px solid #eeeeee;
}

.side-bar-item.special-offers a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p,
  span {
    color: #cc0000;
    opacity: 1;
  }
}

.sm-links-container {
  border: 0.5px solid #d5d5d5;
}

.css-vt2ql5-MuiFormControl-root-MuiTextField-root input {
  border-bottom: 1px solid #005288;
}

.product-details-tab {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 0.5rem 1rem;
}

table > tr:first-child td {
  font-size: 12px;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
  background-color: #f5f5f5;
  padding: 0.5rem;
  color: #000000 !important;
}

table > tr:not(:first-child) td {
  font-size: 12px;
  font-family: "Lexend", sans-serif;
  padding: 0.5rem;
  color: black !important;
}

table > tr td:not(:last-child) {
  border-right: 0.5px solid #b8b8b8;
}

table > tr td:first-child {
  border-top-left-radius: 12px;
}

table > tr td:last-child {
  border-top-right-radius: 12px;
}

table tr:not(:last-child) {
  border-bottom: 0.5px solid #b8b8b8;
}

.review-card {
  border: 0.5px solid #b8b8b8;
  border-radius: 12px;
  padding: 0.75rem;
}

.basic-info,
// .shipping-address,
.card-info,
.order-card {
  border: 0.5px solid #d5d5d5;
  border-radius: 12px;
}

.order-id-badge {
  background-color: rgba(0, 82, 136, 0.1);
  border-radius: 11px;
  margin-right: 3rem;
}

.discount-badge {
  background-color: rgba(204, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;

  .original-price,
  .discounted-price {
    color: #cc0000;
    opacity: 100%;
    font-family: "Lexend", sans-serif;
    font-size: 12px;
  }
}

.new-arrival-side-bar {
  overflow: hidden;
  position: relative;
}

.new-arrival-side-bar::before {
  content: "New";
  position: absolute;
  top: 24px;
  left: -21px;
  width: 56px;
  text-align: center;
  transform: rotate(45deg);
  background: $scheme-color;
  padding: 6px 0;
  color: white;
  font-size: 10px;
  padding-top: 0;
  padding-bottom: 6px;
  font-family: "Lexend", sans-serif;
}

.swal2-popup {
  font-family: "Lexend", sans-serif;
}

.swal2-html-container {
  font-family: "Lexend", sans-serif;
}

.slick-dots.slick-dots-bottom {
  li.slick-active {
    width: 15px;
    height: 15px;

    button {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  li {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      opacity: 1;
    }
  }
}

.MuiRating-root.MuiRating-sizeSmall {
  gap: 0.2rem;
}

.ant-form-item-required::before {
  content: none !important;
}

label.ant-form-item-required {
  font-size: 13px;
  color: #005288 !important;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
}

.ant-form-item-explain-error {
  font-size: 13px;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
}

.ant-form-item-label {
  padding: 0 !important;
}

button {
  border: 1px solid $scheme-color;
  color: $scheme-color;
}

.footer-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.payment-order-detail-heading {
  color: #333;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.product-detail-bredcrum {
  color: #aaa;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
  :hover {
    color: #333;
  }
  span {
    color: #333;
  }
}

.product-detail-pn {
  color: #333;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;

  span {
    font-weight: 400;
  }
}

.product-detail-bn {
  color: #aaa;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.product-detail-l {
  color: #aaa;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}
@media (max-width: 767px) {
  .filters-wrapper .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
 
}

@media (min-width: 768px) {
  .filters-wrapper .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

}
