.swiper-container {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding: 10px;
}
.swiper-container .swiper-button-prev {
  left: -40px;
}
.swiper-container .swiper-button-next {
  right: -40px;
}
.swiper-pagination {
  bottom: 0px !important;
}
.sample-slider {
  /* width:70%; */ /* remove */
}
.swiper-pagination-bullet-active {
  background: #333 !important;
}
